import * as CryptoJS from 'crypto-js'
import { toStringHDMS } from 'ol/coordinate';


// TZ个格式的日期格式化 无参获取当前时间
export const dateFormatter = (date) => {
  // 日期格式化
  let time = date ? new Date(date) : new Date();
  console.log(String(time.getMonth() + 1))
  let year = time.getFullYear();
  let month = String(time.getMonth() + 1).padStart(2, '0');
  let day = String(time.getDate()).padStart(2, '0');
  // let hh = String(time.getHours()).padStart(2, '0');
  // let mm = String(time.getMinutes()).padStart(2, '0');
  // let ss = String(time.getSeconds()).padStart(2, '0');
  // let result = `${year}-${month}-${day} ${hh}:${mm}:${ss}`
  let result = `${year}-${month}-${day}`
  return result;
}



/**
 * 获取树结构数组下指定值的对象
 * @param {any} value 要查找的值
 * @param {Array} list 树结构数组
 * @param {String} valueField 要查找值的字段名
 * @param {String} childrenField 子级字段名
 * @returns {Object} 返回跟value匹配的对象
 * 如 getTreeData(2, list, 'id', 'children') ==> { id: 2, name: 'test' }
 */
export function getTreeData(value, list, valueField, childrenField) {
  for (let i = 0; i < list.length; i++) {
    if (list[i][valueField] == value) {
      return list[i]
    } else {
      if (list[i][childrenField]?.length) {
        const menu = getTreeData(value, list[i][childrenField], valueField, childrenField)
        if (menu) {

          return menu
        }
      }
    }
  }
  return null
}


/**
 * 递归数组每一项并执行 callback 函数
 * @param {Array[any]} list 要递归的数组
 * @param {String} childrenField children字段名
 * @param {Function(dataItem)} callback 递归每项数据时都执行的回调函数，回调参数为当前项数据，当在函数中 return false 时停止递归
 */
export function recursion(list, childrenField, callback) {
  let flag = true
  for (let i = 0; i < list.length; i++) {
    const item = list[i]
    if (callback(item) !== false) {
      if (item[childrenField]?.length) {
        if (!recursion(item[childrenField], childrenField, callback)) {
          break
        }
      }
    } else {
      flag = false
      break
    }
  }
  return flag
}


/**
 * 对象深拷贝
 */
export const deepClone = data => {
  var type = getObjType(data)
  var obj
  if (type === 'array') {
    obj = []
  } else if (type === 'object') {
    obj = {}
  } else {
    // 不再具有下一层次
    return data
  }
  if (type === 'array') {
    for (var i = 0, len = data.length; i < len; i++) {
      obj.push(deepClone(data[i]))
    }
  } else if (type === 'object') {
    for (var key in data) {
      obj[key] = deepClone(data[key])
    }
  }
  return obj
}

/**
 *加密处理
 */
export const encryption = (params) => {
  let {
    data,
    type,
    param,
    key
  } = params
  const result = JSON.parse(JSON.stringify(data))
  if (type === 'Base64') {
    param.forEach(ele => {
      result[ele] = btoa(result[ele])
    })
  } else {
    param.forEach(ele => {
      var data = result[ele]
      key = CryptoJS.enc.Latin1.parse(key)
      var iv = key
      // 加密
      var encrypted = CryptoJS.AES.encrypt(
        data,
        key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding
      })
      result[ele] = encrypted.toString()
    })
  }
  return result
}


// 倒计时处理
export const timer = (max) => {
  let data = {};
  let current = max;
  let decreseInterval = setInterval(() => {
    if (current <= max && current >= 0) {
      current--;
      data = {
        flag: false,
        current: current
      }
    } else if (current < 0) {
      data = {
        flag: true,
        current: current
      }
      clearInterval(decreseInterval);
    }
    console.log(data, 'data')
    return data;
  }, 1000)
}

// 递归数组最后一项为空
export function removeEmptyChildren(arr) {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].children && arr[i].children.length > 0) {
      removeEmptyChildren(arr[i].children);
    } else {
      delete arr[i].children;
    }
  }
  return arr;
}


// 处理行政区划数据最后一级没有数据出现空白面板
export function split(data) {
  data.forEach((ele) => {
    // ele.value = ele.id; //创建一个value字段
    if (ele.list.length === 0) {
      //如果children为空，则删除children
      delete ele.list;
    } else {
      //children不为空表示里面仍然有子节点，继续遍历
      split(ele.list);
    }
  })
  return data;
}

// 根据传入的时间与当前时间进行比较
export function moment(date) {
  let today = new Date();
  let day = new Date(date);
  if (day <= today) {
    // 在当前天之前含今天
    return -1;
  } else {
    // 在当前天之后
    return 1;
  }
}
// 解决小数点会出现多位多余的浮点数据；
// s是数值，n是保留多少位小数
export function formatMoney(s, n) {
  s = Number(s)
  let morethanZero = true
  if (s >= 0) {
    morethanZero = true
  } else {
    morethanZero = false
  }
  s = Math.abs(s)
  n = n > 0 && n < 20 ? n : 2
  s = parseFloat((s + "").replace(/[^\d\.-]/g)).toFixed(n) + ""
  let l = s.split(".")[0].split("").reverse()
  let r = s.split(".")[1]
  let t = ""
  for (let i = 0; i < l.length; i++) {
    t += l[i] + ((i + 1) % 3 == 0 && (i + 1) != l.length ? "," : "")
  }
  return morethanZero ? t.split("").reverse().join("") + "." + r : "-" + t.split("").reverse().join("") + "." + r
}

/**
 * 对象数组筛选
 * @param data 源数组
 * @param attr 根据属性筛选
 * @param value 筛选的值
 */
export const typeFilter = (data, attr, value) => {
  // 筛选
  let res = {};
  let obj = {}, index = 0;
  for (let i = 0; i < data.length; i++) {
    if (data[i][attr] === value) {
      obj = data[i];
      index = i;
      res = {
        obj,
        index
      }
      break;
    }
  }
  console.log(res, 'filter')
  return res;
}
