import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import getters from './getters'
import website from '@/util/website'

Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    website: website
  },
  modules: {
    user,
  },
  getters
})

export default store
