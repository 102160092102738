import axios from "axios";
import { serialize } from "../js/utils/util";
import { getStore } from "../js/utils/store";
import NProgress from "nprogress"; // progress bar
import errorCode from "../const/errorCode";
import router from "../router/index";
// import { Message } from 'element-ui'
import { message as Message } from "../js/utils/message";
import "nprogress/nprogress.css";
import qs from "qs";
import store from "../store"; // progress bar style
axios.defaults.timeout = 50000;
// 返回其他状态吗
axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500; // 默认的
};
// 跨域请求，允许保存cookie
// axios.defaults.withCredentials = true
// NProgress Configuration
NProgress.configure({
  showSpinner: false,
});

// HTTPrequest拦截
axios.interceptors.request.use(
  (config) => {
    NProgress.start(); // start progress bar
    const TENANT_ID = getStore({ name: "tenantId" });
    const isToken = (config.headers || {}).isToken === false;
    // const token = store.getters.access_token
    const token = getStore({ name: "access_token" });
    let oldToken = sessionStorage.getItem("oldToken");
    if (token && oldToken && token != oldToken) {
      sessionStorage.setItem("oldToken", token);
      window.location.reload();
    }
    // if(!token){
    //   Message({
    //     message: '登录已过期',
    //     type: 'error'
    //   })
    //   store.dispatch("LogOut").then(() => {
    //     router.push({ name: "homepage" });
    //   });
    // }
    const userId = store.getters.userInfo.id;
    // const identity = store.getters.identityObj?store.getters.identityObj:{};
    const identity = getStore({ name: "identityObj" })
      ? getStore({ name: "identityObj" })
      : {};
    if (identity && identity.identityId && identity.identityTypeCode) {
      config.headers["Identity-Id"] = identity.identityId;
      config.headers["Identity-Type"] = identity.identityTypeCode;
    }
    if (token && !isToken) {
      config.headers["Authorization"] = "Bearer " + token; // token
      // config.headers['userId'] = userId// userId
    }
    if (TENANT_ID) {
      config.headers["TENANT-ID"] = TENANT_ID; // 租户ID
    }
    // config.headers['VERSION'] = "TGF"
    // headers中配置serialize为true开启序列化
    // if (config.method === 'post' && config.headers.serialize) {
    //   config.data = serialize(config.data)
    //   delete config.data.serialize
    // }
    if (config.isForm) {
      config.headers["Content-Type"] = "application/x-www-form-urlencoded";
      config.data = qs.stringify(config.data);
    }

    // if (config.method === 'get') {
    //   config.paramsSerializer = function (params) {
    //     return qs.stringify(params, { arrayFormat: 'repeat' })
    //   }
    // }

    return config;
  },
  (error) => {
   
    return Promise.reject(error);
    
  }
);

// HTTPresponse拦截
// axios.interceptors.response.use(res => {
//   NProgress.done()
//   const status = Number(res.status) || 200
//   const message = res.data.msg || errorCode[status] || errorCode['default']
//   if (status === 401) {
//     Message({
//       message: message,
//       type: 'error'
//     })
//     store.dispatch('FedLogOut').then(() => {
//       router.push({ name: 'login' })
//     })
//     return
//   }
//   if (status === 426) {
//     Message({
//       message: message,
//       type: 'error'
//     })
//     return
//   }
//   if (status === 428 ||status === 400 ) {
//     Message({
//       message: message,
//       type: 'error'
//     })
//     return
//   }

//   if (status !== 200) {
//     Message({
//       message: message,
//       type: 'error'
//     })
//     return Promise.reject(new Error(message))
//   }

//   return res.data
// }

axios.interceptors.response.use(
  (res) => {
    NProgress.done(); // 结束进度条
    const status = Number(res.status) || 200; // 获取响应状态码，默认为 200
    const message = res.data.msg || errorCode[status] || errorCode["default"]; // 提取错误信息，默认使用预设的错误码信息

    // 根据不同的状态码进行处理
    if (status === 401) {
      Message({
        message: message,
        type: "error",
      });
      store.dispatch("FedLogOut").then(() => {
        router.push({ name: "login" });
      });
      return;
    }
    if (status === 426 || status === 400 || status === 428) {
      Message({
        message: message,
        type: "error",
      });
      return;
    }
    if (status >= 500) {
      // 服务器错误，显示错误信息
      Message({
        message: "服务器错误，请稍后重试",
        type: "error",
      });
      return;
    }
    if (status === 413) {
      // 请求实体过大，显示错误信息
      Message({
        message: '文件过大，上传失败',
        type: 'error'
      });
      return;
    }
    if (status === 404) {
      // 资源未找到，显示错误信息
      Message({
        message: '请求的资源未找到',
        type: 'error'
      });
      return;
    }
    if (status === 429) {
      // 请求过多，显示错误信息
      Message({
        message: "请求过多，请稍后再试",
        type: "error",
      });
      return;
    }

    // 对于其他状态码，直接显示错误信息
    if (status !== 200) {
      Message({
        message: message,
        type: "error",
      });
      return Promise.reject(new Error(message));
    }

    return res.data; // 返回响应数据
  },

  (error) => {
    NProgress.done();
    if (axios.isCancel(error)) {
      console.log('请求被取消');
      // 这里可以添加提示用户请求被取消的逻辑
      Message({
        message: "请求失败，请重试",
        type: "error",
      });
      return;
    } else {
      console.log(error);
    }
    return Promise.reject(new Error(error));
  }
);

export default axios;
