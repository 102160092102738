import Vue from "vue";
import VueRouter from "vue-router";
import AvueRouter from './avue-router';
import PageRouter from './page/';
import Store from '../store/';
// 解决Vue-Router升级导致的Uncaught(in promise) navigation guard问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) { return originalPush.call(this, location, onResolve, onReject) }
  return originalPush.call(this, location).catch((err) => err)
}

Vue.use(VueRouter);
export const createRouter = () => new VueRouter({
  routes: [...PageRouter]
})
const Router = createRouter();
  AvueRouter.install(Router, Store);
  Router.$avueRouter.formatRoutes(Store.state.user.vipLeftTree, true)
// 重置路由
export function resetRouter () {
  const newRouter = createRouter()
  Router.matcher = newRouter.matcher
  AvueRouter.install(Router, Store)
}

export default Router;
