<template>
  <div id="app">
    <my-header />
    <router-view></router-view>
    <my-footer />
  </div>
</template>
<script>
import myHeader from "./components/header/index";
import myFooter from "./components/footer/index";
import { mapGetters } from "vuex";

export default {
  components: {
    myHeader,
    myFooter,
  },
  computed: {
    ...mapGetters(["identityObj"]),
  },
  data: () => ({}),
};
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  min-width: 1200px;
  overflow-y: auto;


}
</style>
