import { mapMutations } from 'vuex';
import { getStore, setStore } from '../js/utils/store'
const RouterPlugin = function () {
  this.$router = null
  this.$store = null
}
RouterPlugin.install = function (router, store) {
  this.$router = router
  this.$store = store
  function isURL(s) {
    return /^http[s]?:\/\/.*/.test(s)
  }
  function objToform(obj) {
    const result = []
    Object.keys(obj).forEach(ele => {
      result.push(`${ele}=${obj[ele]}`)
    })
    return result.join('&')
  }
  function createObj(Menu0, propsDefault) {
    const meta = {
      keepAlive: Number(Menu0['keepAlive']) === 1
    }
    const object = {
      // icon: icon ? icon : 'el-icon-share',
      name: Menu0[propsDefault.path],
      meta: { keepAlive: false },
      path: Menu0.allPath,
      children: []
    }
    object.component = () => import('@/views' + Menu0.allPath + '/index.vue');
    return object;
  }
  function getRouteChild(arr, propsDefault, firstRouter) {
    let pathList = [];
    firstRouter.children = [];
    arr.forEach(level1 => {
      let path = level1[propsDefault.path];
      if (!pathList.includes(path)) {
        pathList.push(path)
        let object = createObj(level1, propsDefault);
        if (level1.children) {
          if (level1.children.length > 0) {
            object.children = getRouteChild(level1.children, propsDefault, object);
          } else {
            object.children = []
          }
        }
        firstRouter.children.push(object);
      } else {
        return
      }
    })
    return firstRouter.children;
  }

  function createRoutesComponent(arr, propsDefault) {
    const firstRouter = {
      path: '/memberCenter',
      name: 'memberCenter',
      component: () => import('@/views/memberCenter/index.vue'),
      children: []
    }
    firstRouter.children = getRouteChild(arr, propsDefault, firstRouter)
    return firstRouter
  }
  this.$router.$avueRouter = {
    // 全局配置
    $website: this.$store.state.website,
    routerList: [],
    group: '',
    safe: this,
    // 处理路由
    getPath: function (params) {
      const { src } = params
      let result = src || '/'
      if (src.includes('http') || src.includes('https')) {
        result = `/myiframe/urlPath?${objToform(params)}`
      }
      return result
    },
    // 正则处理路由
    vaildPath: function (list, path) {
      let result = false
      list.forEach(ele => {
        if (new RegExp('^' + ele + '.*', 'g').test(path)) {
          result = true
        }
      })
      return result
    },
    // 设置路由值
    getValue: function (route) {
      let value = ''
      if (route.query.src) {
        value = route.query.src
      } else {
        value = route.path
      }
      return value
    },
    // 动态路由
    formatRoutes: function (aMenu = [], first) {
      const propsConfig = this.$website.menu.props
      const propsDefault = {
        label: propsConfig.label || 'label',
        path: propsConfig.path || 'path',
        icon: propsConfig.icon || 'icon',
        children: propsConfig.children || 'children',
        meta: propsConfig.meta || 'meta'
      }
      let aRouter = createRoutesComponent(aMenu, propsDefault);
      if (first) {
        this.safe.$router.addRoute(createRoutesComponent(aMenu, propsDefault));
      } else {
        return aRouter
      }
    }
  }
}
export default RouterPlugin
